import { createClient } from 'https://cdn.jsdelivr.net/npm/@supabase/supabase-js/+esm';
import { getElementIdOfCurrentPage, getBranchIdofCurrentPage } from '../global/utils.js';

let supabase = null;

export function createSupabaseClient() {
    if (!supabase) {
        supabase = createClient(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_ANON_KEY);
    }
    return supabase;
}

export async function searchItems(inputValue, elementType, branchId) {
    const workflowId = getElementIdOfCurrentPage();
    let query;
    switch (elementType) {
        case 'user story':
            query = await supabase
                .from('user_storys')
                .select('name, uuid')
                .ilike('name', `%${inputValue}%`)
                .match({ workflow_uuid: workflowId, branch_id: branchId })
                .or('branch_status.not.eq.deleted,branch_status.is.null');
            break;
        case 'version':
            query = await supabase.from('branches').select().ilike('name', `%${inputValue}%`);
            break;
        case 'file':
            query = await supabase
                .from('files')
                .select('name, uuid, type')
                .ilike('name', `%${inputValue}%`)
                .match({ branch_id: branchId, organization_uuid: (await getUserOrganizations())[0].organization_uuid })
                .or('branch_status.not.eq.deleted,branch_status.is.null');
            break;
        case 'issue':
            query = await supabase
                .from('issues')
                .select('name, uuid')
                .ilike('name', `%${inputValue}%`)
                .match({ workflow_uuid: workflowId, branch_id: branchId })
                .or('branch_status.not.eq.deleted,branch_status.is.null');
            break;
        case 'section':
            query = await supabase
                .from('sections')
                .select()
                .ilike('name', `%${inputValue}%`)
                .match({ workflow_uuid: workflowId, branch_id: branchId })
                .or('branch_status.not.eq.deleted,branch_status.is.null');
            break;
    }

    const { data, error } = query;

    if (error) {
        console.error('Error searching items:', error);
        return;
    }

    return data;
}

export async function updateItemInSupabase(itemId, updateParams, type) {
    const branchId = getBranchIdofCurrentPage();
    const branchStatus = await getBranchStatus(itemId, branchId, type);

    if (branchStatus === null && branchId !== 'main') {
        updateParams.branch_status = 'modified';
    }

    const { error } = await supabase.from(`${type}s`).update(updateParams).match({ uuid: itemId, branch_id: branchId });
    if (error) {
        console.error(error);
    }
}

export async function updateItemInSupabaseWithBranch(itemId, updateParams, type, branchId) {
    const branchStatus = await getBranchStatus(itemId, branchId, type);

    if (branchStatus === null && branchId !== 'main') {
        updateParams.branch_status = 'modified';
    }

    const { error } = await supabase.from(`${type}s`).update(updateParams).match({ uuid: itemId, branch_id: branchId });
    if (error) {
        console.error(error);
    }
}

export async function updateItemsInSupabase(items, type) {
    const branchId = getBranchIdofCurrentPage();

    let allUpdates = [];

    const branchStatuses = await getBranchStatuses(
        items.map((item) => item.uuid),
        branchId,
        type
    );

    for (const item of items) {
        const branchStatus = branchStatuses[item.uuid];
        if (branchStatus === null && branchId !== 'main') {
            allUpdates.push({ ...item, branch_status: 'modified', branch_id: branchId });
        } else {
            allUpdates.push({ ...item, branch_id: branchId }); // Keep the current branch status
        }
    }

    if (allUpdates.length > 0) {
        for (const update of allUpdates) {
            const { uuid, ...updateFields } = update;
            const { error } = await supabase.from(`${type}s`).update(updateFields).match({ uuid: uuid, branch_id: branchId });

            if (error) {
                console.error(`Error updating ${type} with UUID ${uuid}:`, error);
            }
        }
    }
}

export async function fetchItems(workflowId, branchId, type) {
    const { data, error } = await supabase
        .from(`${type}s`)
        .select()
        .match({ workflow_uuid: workflowId, branch_id: branchId })
        .or('branch_status.not.eq.deleted,branch_status.is.null');

    if (error) {
        console.error(`Error fetching ${type}s:`, error);
        return null;
    }

    return data;
}

export async function fetchItem(itemId, type, branchId) {
    const organizationId = (await getUserOrganizations())[0]?.organization_uuid;
    let query = supabase.from(`${type}s`).select().eq('uuid', itemId).eq('branch_id', branchId);

    if (organizationId !== null) {
        query = query.eq('organization_uuid', organizationId);
    }

    const { data, error } = await query;

    if (error) {
        console.error(error);
        return;
    }

    return data[0];
}

export async function fetchOrgItem(itemId, type, branchId, organizationId) {
    const { data, error } = await supabase
        .from(`${type}s`)
        .select()
        .eq('uuid', itemId)
        .eq('branch_id', branchId)
        .eq('organization_uuid', organizationId);

    if (error) {
        console.error(error);
        return;
    }

    return data[0];
}

export async function fetchDeletedItems(workflowId, branchId, type) {
    const { data, error } = await supabase
        .from(`${type}s`)
        .select()
        .match({ workflow_uuid: workflowId, branch_id: branchId, branch_status: 'deleted' });

    if (error) {
        console.error(error);
        return;
    }
    return data;
}

export async function deleteItemInSupabase(itemId, type) {
    const branchId = getBranchIdofCurrentPage();
    const branchStatus = await getBranchStatus(itemId, branchId, type);
    let query;

    if ((branchStatus === null || branchStatus === 'modified') && branchId !== 'main') {
        query = await supabase.from(`${type}s`).update({ branch_status: 'deleted' }).match({ uuid: itemId, branch_id: branchId });
    } else {
        query = await supabase.from(`${type}s`).delete().match({ uuid: itemId, branch_id: branchId });
    }

    const { error } = await query;
    if (error) {
        console.error(error);
    }
}

export async function addItemToSupabase(itemId, itemDetails, workflowId, type) {
    const branchId = getBranchIdofCurrentPage();
    const {
        data: { user }
    } = await supabase.auth.getUser();
    const userId = user.id;
    const { data, error: selectError } = await supabase.from('user_organizations').select('organization_uuid').eq('user_uuid', userId);

    if (selectError) {
        console.error('Error fetching user organizations:', selectError);
        return;
    }

    const organizationId = data[0]?.organization_uuid;
    const branchStatus = branchId === 'main' ? null : 'added';

    const { error: upsertError } = await supabase.from(`${type}s`).upsert([
        {
            ...itemDetails,
            uuid: itemId,
            workflow_uuid: workflowId,
            branch_id: branchId,
            branch_status: branchStatus,
            organization_uuid: organizationId
        }
    ]);

    if (upsertError) {
        console.error(upsertError);
    }
}

export async function getBranchStatus(itemId, branchId, type) {
    const { data, error } = await supabase.from(`${type}s`).select('branch_status').match({ uuid: itemId, branch_id: branchId });

    if (error) {
        console.error(error);
        return;
    }

    return data[0].branch_status;
}

export async function getBranchStatuses(itemIds, branchId, type) {
    let branchStatusMap = {};
    const { data, error } = await supabase.from(`${type}s`).select('uuid, branch_status').in('uuid', itemIds).match({ branch_id: branchId });

    if (error) {
        console.error(`Error fetching branch statuses for ${type}s:`, error);
        return branchStatusMap;
    }

    data.forEach((item) => {
        branchStatusMap[item.uuid] = item.branch_status;
    });

    return branchStatusMap;
}

export async function getUserOrganizations() {
    const { data: userSession, error: sessionError } = await supabase.auth.getSession();
    const userId = userSession.session.user.id;
    const { data, error } = await supabase.from('user_organizations').select('organization_uuid').eq('user_uuid', userId);

    if (error) {
        console.error('Error fetching user organizations:', error);
        return;
    }

    return data;
}

export async function getUserId() {
    const { data: userSession, error: sessionError } = await supabase.auth.getSession();
    return userSession.session.user.id;
}

export async function getUserData() {
    const userId = await getUserId();
    const { data, error } = await supabase.from('users').select().eq('uuid', userId);

    if (error) {
        console.error('Error fetching user data:', error);
        return;
    }
    return data;
}

export async function getUserSupabaseData() {
    const { data: userSession, error: sessionError } = await supabase.auth.getSession();
    return userSession.session.user;
}

export async function getUsersInOrganization(organizationId) {
    const { data: userOrganizations, error } = await supabase.from('user_organizations').select('user_uuid').eq('organization_uuid', organizationId);

    if (error) {
        console.error('Error fetching users in organization:', error);
        return;
    }

    const userData = await Promise.all(
        userOrganizations.map(async (userOrganization) => {
            const { data, error: userError } = await supabase.from('users').select().eq('uuid', userOrganization.user_uuid).single();

            if (userError) {
                console.error('Error fetching user data:', userError);
                return null;
            }

            return data;
        })
    );

    return userData.filter((user) => user !== null);
}
